import React, { useEffect, useState } from 'react';
import Form from '../components/Form';

// Correct

function Restock() {
  const [itemName, setItemName] = useState(undefined);
  useEffect(() => {
    setItemName(localStorage.itemName);
  }, []);
  // var itemName = ;
  return (
    <Form
      from="restock"
      title="heads up on restock"
      text="drop your addy and we’ll shoot you a line when it's back in stock"
      itemName={itemName}
    />
  );
}
export default Restock;
